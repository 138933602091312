import { kebabCase } from 'lodash'

import { PaginationModel, transformPaginationDto } from '@marketplace-web/domain/pagination-data'
import { Response } from '@marketplace-web/shared/api-client'
import { transformUserDto, transformUserDtos } from '@marketplace-web/shared/user-data'
import { AccessChannel } from 'constants/access-channel'
import { CountriesResp } from 'types/api/response/countries-resp'
import { BrandsResp } from 'types/api/response/brands-resp'
import { FeedbackRatingOptionsResp } from 'types/api/response/feedback-rating-options-resp'
import { GetItemUploadFeedbackRatingsResp } from 'types/api/response/get-item-upload-feedback-ratings-resp'
import { ClosetPromotionPricingResp } from 'types/api/response/closet-promotion-pricing-resp'
import { PhotoTipsResp } from 'types/api/response/photo-tips-resp'
import { UserSizesResp } from 'types/api/response/user-sizes-resp'
import { GetSizeGroupsResp } from 'types/api/response/get-size-groups-resp'
import { NotificationSettingsResp } from 'types/api/response/notification-settings-resp'
import { FaqEntryResp } from 'types/api/response/faq-entry-resp'
import { UsersResp } from 'types/api/response/users-resp'
import { GetIncomeSettingsResp } from 'types/api/response/get-income-settings-resp'
import { GetInvoicesAvailableDatesResp } from 'types/api/response/get-invoices-available-dates-resp'
import { UserResp } from 'types/api/response/user-resp'
import { SendEmailVerificationCodeResp } from 'types/api/response/send-email-verification-code-resp'
import { GetInfoBoardPostsResp } from 'types/api/response/get-info-board-posts-resp'
import { GetTermsAndConditionsResp } from 'types/api/response/get-terms-and-conditions-resp'
import { GetInfoBoardPostResp } from 'types/api/response/get-info-board-post-resp'
import { GetNotificationLandingsResp } from 'types/api/response/get-notification-landings-resp'
import { MyOrdersResp } from 'types/api/response/my-orders-resp'
import { PhotoTipResp } from 'types/api/response/photo-tip-resp'
import { FaqEntriesSearchResp } from 'types/api/response/faq-entries-search-resp'
import { GetTermsAndConditionsContentResp } from 'types/api/response/get-terms-and-conditions-content-resp'
import { FaqEntryRecommendedFaqsResp } from 'types/api/response/faq-entry-recommended-faqs-resp'
import { FaqEntryRelatedFaqsResp } from 'types/api/response/faq-entry-related-faqs-resp'
import { BusinessAccountsTermsOfUseResp } from 'types/api/response/business-accounts-terms-of-use-resp'
import { ReferralShareResp } from 'types/api/response/referral-share-resp'
import { ReferralInviteInstructionsResp } from 'types/api/response/referral-invite-instructions-resp'
import { ProfileUserInfoResp } from 'types/api/response/profile-user-info-resp'
import { GetOfflineVerificationFeedbackResp } from 'types/api/response/get-offline-verification-feedback-resp'
import { CreateReferrerResp } from 'types/api/response/create-referrer-resp'
import { GetSellerOfferOptionsResp } from 'types/api/response/get-seller-offer-options-resp'
import { BusinessAccountAddressesResp } from 'types/api/response/business-account-addresses-resp'
import { GetItemEscrowFeesResp } from 'types/api/response/get-item-escrow-fees-resp'
import { GetVasEntryPointsResp } from 'types/api/response/get-vas-entry-points-resp'
import { PromotedClosetOrderResp } from 'types/api/response/promoted-closet-order-resp'
import { SellerBadgesResp } from 'types/api/response/seller-badges-resp'
import { PromotedClosetsListResp } from 'types/api/response/promoted-closets-list-resp'
import { BumpMinimumPriceResp } from 'types/api/response/bump-minimum-price-resp'
import { PrepareBumpOrderResp } from 'types/api/response/prepare-bump-order-resp'
import { BumpOptionResp } from 'types/api/response/bump-option-resp'
import { UserBundleItemsResp } from 'types/api/response/user-bundle-items-resp'
import { GetSellerInsightsResp } from 'types/api/response/get-seller-insights-resp'
import { InfluencerResp } from 'types/api/response/influencer-resp'
import { GetModelAttributeConfigurationResp } from 'types/api/response/get-model-attribute-configuration-resp'
import { GetUploadFormModelsResp } from 'types/api/response/get-upload-form-models-resp'
import { GetSellerFiltersResp } from 'types/api/response/get-seller-filters-resp'
import { GetLabelOptionsResp } from 'types/api/response/get-label-options-resp'
import { GetWardrobeItemsResp } from 'types/api/response/get-wardrobe-items-resp'
import { GetRequestReturnDetailsResp } from 'types/api/response/get-request-return-details-resp'
import { DefaultShippingAddressResp } from 'types/api/response/default-shipping-address-resp'
import { GetReturnShippingOptionsResp } from 'types/api/response/get-return-shipping-options-resp'
import { ShippingContactConfigurationResp } from 'types/api/response/shipping-contact-configuration-resp'
import { GetSellerInsightsPhotoRecommendationsResp } from 'types/api/response/get-seller-insights-photo-recommendations-resp'
import { CustomShipmentConfigResp } from 'types/api/response/custom-shipment-config-resp'
import { DigitalLabelResp } from 'types/api/response/digital-label-resp'
import { GetShippingInstructionsResp } from 'types/api/response/get-shipping-instructions-resp'
import { TrackingJourneySummaryResponse } from 'types/api/response/tracking-journey-summary-response'
import { ShipmentInstructionsResponse } from 'types/api/response/shipment-instructions-response'
import { GetCollectionDatesResp } from 'types/api/response/get-collection-dates-resp'
import { GetVideoGameRatingsResp } from 'types/api/response/get-video-game-ratings-resp'
import { GetCarrierSettingsFeedbackConfigurationResp } from 'types/api/response/get-carrier-settings-feedback-configuration-resp'
import { GetAvailableCarriersResp } from 'types/api/response/get-available-carriers-resp'
import { CountryBoundsResponse } from 'types/api/response/country-bounds-response'
import { NearbyPickupPointsResp } from 'types/api/response/nearby-pickup-points-resp'
import { ItemPerformanceResponse } from 'types/api/response/item-performance-response'
import { GetPublicPhotosResp } from 'types/api/response/get-public-photos-resp'
import { PackageSizesResp } from 'types/api/response/package-sizes-resp'
import { GetBumpableItemsResp } from 'types/api/response/get-bumpable-items-resp'
import { ReserveItemDetailsResp } from 'types/api/response/reserve-item-details-resp'
import { MarkAsSoldItemDetailsResp } from 'types/api/response/mark-as-sold-item-details-resp'
import { ElectronicsLandingPageResp } from 'types/api/response/electronics-landing-page-resp'
import { NewAgainLandingPageResp } from 'types/api/response/new-again-landing-page-resp'
import { GetParcelDetailsResp } from 'types/api/response/get-parcel-details-resp'
import { GetBumpsNewMemberItemUploadMotivationResp } from 'types/api/response/get-bumps-new-member-item-upload-motivation-resp'
import { PickUpParcelCodeResponse } from 'types/api/response/pick-up-parcel-code-response'
import { GetPackageSizesSuggestionResp } from 'types/api/response/get-package-sizes-suggestion-resp'
import { GetEscrowOrderShipmentResp } from 'types/api/response/get-escrow-order-shipment-resp'
import { GetEscrowOrderComplaintInfoResp } from 'types/api/response/get-escrow-order-complaint-info-resp'
import { GetShippingPreferencesResp } from 'types/api/response/get-shipping-preferences-resp'
import { GetEscrowOrderActionsResp } from 'types/api/response/get-escrow-order-actions-response'
import { CountryModel } from 'types/models/country'
import { FeedbackRatingModel } from 'types/models/help-center-feedback'
import { BrandModel } from 'types/models/brand'
import { ClosetPromotionPricingModel } from 'types/models/closet-promotion'
import { HelpCenterFaqEntryModel, HelpCenterQuestionModel } from 'types/models/help-center'
import { WalletIncomeSettingsModel } from 'types/models/wallet-income'
import { InvoicesAvailableDatesModel } from 'types/models/wallet-invoices'
import { ReferralShareModel, ReferralInviteInstructionsModel } from 'types/models/referral'
import { ProductItemModel } from 'types/models/product-item'
import { InfluencerLandingPageModel } from 'types/models/influencer'
import { VideoGameRatingModel } from 'types/models/video-game-rating'
import { CarrierSettingsFeedbackConfigurationModel } from 'types/models/carrier-settings-feedback'
import { ElectronicsLandingPageModel } from 'types/models/electronics-landing-page'
import { NewAgainLandingPageModel } from 'types/models/new-again-landing-page'
import { ShippingPreferencesModel } from 'types/models/shipping-preference'
import { transformCountryDto } from 'data/transformers/country'
import { transformBrandDto } from 'data/transformers/brand'
import { transformEscrowFees } from 'data/transformers/escrow-fees'
import {
  transformClosetPromotionOrder,
  transformClosetPromotionPricing,
} from 'data/transformers/closet-promotion'
import { transformPhotoTipDto, transformPhotoTipDtos } from 'data/transformers/photo-tip'
import { transformShippingOptionDtos } from 'data/transformers/shipping-option'
import { transformFeedbackFormDto } from 'data/transformers/feedback-form'
import { transformNotificationSettingsDto } from 'data/transformers/notification-settings'
import { HomepageBlocksModel } from 'types/models/homepage-blocks'
import { HomepageBlocksDto } from 'types/dtos/homepage-blocks'
import { EmailVerificationCodeModel } from 'types/models/email-verification-code'
import { transformTermsAndConditionsDto } from 'data/transformers/terms-and-conditions'
import {
  transformInfoBoardPostDto,
  transformInfoBoardPostDtos,
} from 'data/transformers/info-board-post'
import { transformProfileUserInfoDto } from 'data/transformers/profile-user-info'
import { transformSellerOfferOptionsDto } from 'data/transformers/seller-offer-options'
import { transformUserBundleItems } from 'data/transformers/bundle-items'
import { transformVideoGameRatingDtos } from 'data/transformers/video-game-rating'
import { transformMyOrderDto } from 'data/transformers/my-order'
import { transformTermsAndConditionsContentDto } from 'data/transformers/terms-and-conditions-content'
import {
  transformBusinessAccountAddressesDto,
  transformBusinessAccountsTermsOfUseDto,
} from 'data/transformers/business-account'
import {
  transformReferralInviteInstructionsDto,
  transformReferralShareDto,
} from 'data/transformers/referral'
import { transformVasEntryPoints } from 'data/transformers/vas-entry-point'
import { transformSellerBadgeDto } from 'data/transformers/user-badge'
import { transformClosets } from 'data/transformers/closet'
import {
  transformBumpItems,
  transformBumpMinimumPrice,
  transformBumpUpOrder,
  transformBumpsOptions,
} from 'data/transformers/bumps'
import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'
import {
  transformInfluencerClosetDto,
  transformInfluencerDisclaimerDto,
  transformInfluencerInspirationDto,
  transformInfluencerIntroductionDto,
  transformInfluencerLifestyleDto,
  transformInfluencerSeoDto,
  transformInfluencerTipsDto,
} from 'data/transformers/influencer'
import { transformHomepageBlockEntities } from 'data/transformers/homepage-blocks'
import { transformLabelOptions } from 'data/transformers/label-options'
import { transformRequestReturnDetailsDto } from 'data/transformers/request-return-details'
import { transformAddress } from 'data/transformers/address'
import { transformWardrobeItemDtosToProductItems } from 'data/transformers/wardrobe-item'
import { transformReturnShippingOptionDtos } from 'data/transformers/return-shipping-option'
import { transformReturnCurrencyConversionDto } from 'data/transformers/return-currency-conversion'
import { transformShippingContactConfigurationDto } from 'data/transformers/shipping-contact'
import { transformCustomShipmentDto } from 'data/transformers/shipment-custom-config'
import { transformDigitalLabel } from 'data/transformers/digital-label'
import { transformShippingInstructionsDto } from 'data/transformers/shipping-instructions'
import { transformTrackingJourneySummary } from 'data/transformers/tracking-journey-summary'
import { transformPickUpParcelCode } from 'data/transformers/pickup-parcel'
import { transformShipmentInstructions } from 'data/transformers/shipment-instructions'
import { transformCollectionDates } from 'data/transformers/collection-dates'
import { transformCarrierSettingsFeedback as transformCarrierSettingsFeedbackConfiguration } from 'data/transformers/carrier-settings-feedback'
import { transformAvailableCarrierDtos } from 'data/transformers/available-carrier'
import { transformCountryBoundsDto } from 'data/transformers/country-bounds'
import { transformPickupPointsDto } from 'data/transformers/pickup-point'
import { transformItemPerformance } from 'data/transformers/item-performance'
import { transformReserveItemDetailsDto } from 'data/transformers/reserve-item'
import { transformMarkAsSoldItemDetailsDto } from 'data/transformers/mark-as-sold-item'
import { transformPackageSizeDto } from 'data/transformers/package-sizes'
import {
  transformElectroncisIntroductionSectionDto,
  transformDiscoverSectionDto,
  transformElectroncisSeoDto,
  transformHowSectionDto,
  transformWhySectionDto,
  transformBottomSectionDto,
  transformDisclaimerSectionDto,
} from 'data/transformers/electronics-landing-page'

import {
  transformNewAgainForNoweverlSectionDto,
  transformNewAgainIntroductionSectionDto,
  transformNewAgainFeelNewAgainDto,
  transformNewAgainPotentialSectionDto,
  transformNewAgainSeoDto,
} from 'data/transformers/new-again'
import { transformEscrowOrderShipmentDto } from 'data/transformers/escrow-order-shipment'
import { transformShippingPreferencesDtoToModel } from 'data/transformers/shipping-preference'
import { transformEscrowOrderActonsDto } from 'data/transformers/escrow-order-actions'

export const transformCountryResponse = (response: Response<CountriesResp>): Array<CountryModel> =>
  response.countries.map(transformCountryDto)

export const transformBrandsResponse = (response: Response<BrandsResp>): Array<BrandModel> =>
  response.brands.map(transformBrandDto)

export const transformFeedbackRatingsResponse = (
  response: Response<
    | FeedbackRatingOptionsResp
    | GetItemUploadFeedbackRatingsResp
    | GetOfflineVerificationFeedbackResp
  >,
): FeedbackRatingModel | undefined => {
  if (!response.feedback_ratings) return undefined

  return transformFeedbackFormDto(response.feedback_ratings)
}

export const transformCarrierSettingsFeedbackConfigurationResponse = (
  response: Response<GetCarrierSettingsFeedbackConfigurationResp>,
): CarrierSettingsFeedbackConfigurationModel | undefined => {
  return transformCarrierSettingsFeedbackConfiguration(response.feedback_ratings)
}

export const transformClosetPromotionPricingResponse = (
  response: Response<ClosetPromotionPricingResp>,
): ClosetPromotionPricingModel => transformClosetPromotionPricing(response.promoted_closet_pricing)

export const transformPhotoTipsResponse = (response: Response<PhotoTipsResp>) =>
  transformPhotoTipDtos(response.photo_tips)

export const transformPhotoTipResponse = (response: Response<PhotoTipResp>) =>
  transformPhotoTipDto(response.photo_tip)

export const transformUserSizesResponse = (response: Response<UserSizesResp>) => ({
  sizeIds: response.size_ids,
})

export const transformSizeGroupsResponse = (response: Response<GetSizeGroupsResp>) => ({
  sizeGroups: response.size_groups,
})

export const transformNotificationSettingsResponse = (
  response: Response<NotificationSettingsResp>,
) => transformNotificationSettingsDto(response.notification_settings.email)

export const transformFaqEntryResponse = ({
  faq_entry,
}: FaqEntryResp): HelpCenterFaqEntryModel | undefined => {
  if (!faq_entry) return undefined

  return {
    id: faq_entry.id,
    title: faq_entry.title,
    body: faq_entry.body,
  }
}

export const transformFaqEntrySearchResponse = ({
  faq_entries,
}: FaqEntriesSearchResp): Array<HelpCenterQuestionModel> | null => {
  if (!faq_entries.length) return null

  return faq_entries.map(({ id, title }) => ({
    id,
    title,
    path: `/help/${kebabCase(`${id}-${title}`)}?access_channel=${AccessChannel.HcSearch}`,
  }))
}

export const transformUsersResponse = (response: UsersResp) => ({
  users: transformUserDtos(response.users),
  pagination: transformPaginationDto(response.pagination),
})

export const transformWalletIncomeSettingsResponse = ({
  is_monthly_report_enabled,
}: GetIncomeSettingsResp): WalletIncomeSettingsModel => ({
  isMonthlyReportEnabled: is_monthly_report_enabled,
})

export const transformInvoicesAvailableDatesResponse = ({
  available_dates,
}: GetInvoicesAvailableDatesResp): InvoicesAvailableDatesModel => ({
  availableDates: [...available_dates],
})

export const transformUserResponse = (response: Response<UserResp>) => ({
  user: transformUserDto(response.user),
})

export const transformHomepageBlockResponse = (
  response: HomepageBlocksDto,
): HomepageBlocksModel => ({
  blocks: transformHomepageBlockEntities(response.blocks),
  nextPageToken: response.next_page_token,
  showLoadMoreButton: response.show_load_more_button,
})

export const transformEmailVerificationCodeResponse = (
  response: Response<SendEmailVerificationCodeResp>,
): EmailVerificationCodeModel => ({
  email: response.email,
  canChangeEmail: response.can_change_email ?? true,
})

export const transformInfoBoardPostsResponse = (response: Response<GetInfoBoardPostsResp>) =>
  transformInfoBoardPostDtos(response.info_posts)

export const transformTermsAndConditionsResponse = (
  response: Response<GetTermsAndConditionsResp>,
) => transformTermsAndConditionsDto(response.terms_and_conditions)

export const transformInfoBoardPostResponse = (response: Response<GetInfoBoardPostResp>) =>
  transformInfoBoardPostDto(response.info_post)

export const transformNotificationLandingsResponse = ({
  notification_landing,
}: Response<GetNotificationLandingsResp>) => ({
  body: notification_landing.body,
  buttonTitle: notification_landing.button_title,
  buttonUrl: notification_landing.button_url,
  code: notification_landing.code,
  id: notification_landing.id,
  imageUrl: notification_landing.image_url,
  title: notification_landing.title,
})

export const transformMyOrdersResponse = (response: Response<MyOrdersResp>) => ({
  orders: response.my_orders.map(transformMyOrderDto),
  pagination: transformPaginationDto(response.pagination),
  isOrderDetailsEnabled: response.order_details_enabled,
})

export const transformTermsAndConditionsContentResponse = (
  response: Response<GetTermsAndConditionsContentResp>,
) => transformTermsAndConditionsContentDto(response)

export const transformFaqEntryRecommendedFaqsResponse = ({
  faq_recommendations,
}: Response<FaqEntryRecommendedFaqsResp>): Array<HelpCenterQuestionModel> | null => {
  if (!faq_recommendations.length) return null

  return faq_recommendations
}

export const transformFaqEntryRelatedFaqsResponse = ({
  related_faqs,
}: Response<FaqEntryRelatedFaqsResp>): Array<HelpCenterQuestionModel> | null => {
  if (!related_faqs.length) return null

  return related_faqs
}

export const transformDefaultShippingAddress = ({
  user_address,
}: Response<DefaultShippingAddressResp>) => {
  if (!user_address) return null

  return transformAddress(user_address)
}

export const transformCountryBounds = ({ country_bounds }: Response<CountryBoundsResponse>) => {
  if (!country_bounds) return null

  return transformCountryBoundsDto(country_bounds)
}

export const transformBusinessAccountAddressesResponse = (
  response: Response<BusinessAccountAddressesResp>,
) => ({
  addresses: transformBusinessAccountAddressesDto(response.addresses),
})

export const transformBusinessAccountsTermsOfUseResponse = (
  response: Response<BusinessAccountsTermsOfUseResp>,
) => transformBusinessAccountsTermsOfUseDto(response)

export const transformReferralShareResponse = ({
  share,
}: Response<ReferralShareResp>): ReferralShareModel => transformReferralShareDto(share)

export const transformReferralInviteInstructionsResponse = ({
  invite_instructions,
}: Response<ReferralInviteInstructionsResp>): ReferralInviteInstructionsModel =>
  transformReferralInviteInstructionsDto(invite_instructions)

export const transformProfileUserInfoResponse = (response: Response<ProfileUserInfoResp>) =>
  transformProfileUserInfoDto(response)

export const transformGetVideoGameRatingResponse = (
  response: Response<GetVideoGameRatingsResp>,
): Array<VideoGameRatingModel> => transformVideoGameRatingDtos(response.video_game_ratings)

export const transformWardrobeItemsResponse = (
  response: Response<GetWardrobeItemsResp>,
): {
  items: Array<ProductItemModel>
  pagination: PaginationModel
} => ({
  items: transformWardrobeItemDtosToProductItems(response.items),
  pagination: transformPaginationDto(response.pagination),
})

export const transformReserveItemDetailsResponse = (response: Response<ReserveItemDetailsResp>) =>
  transformReserveItemDetailsDto(response.reserve_item_details)

export const transformMarkAsSoldItemDetailsResponse = (
  response: Response<MarkAsSoldItemDetailsResp>,
) => transformMarkAsSoldItemDetailsDto(response.mark_as_sold_item_details)

export const transformCreateReferrerResponse = (response: CreateReferrerResp) => ({
  referralInviteUrl: response.referral_invite_url,
  referrerId: response.referrer_id,
})

export const transformSellerOfferOptionsResponse = (
  response: Response<GetSellerOfferOptionsResp>,
) => transformSellerOfferOptionsDto(response.seller_offer_options)

export const transformItemEscrowFeesResponse = (response: Response<GetItemEscrowFeesResp>) =>
  transformEscrowFees(response.escrow_fees)

export const transformVasEntryPointsResponse = (response: Response<GetVasEntryPointsResp>) =>
  transformVasEntryPoints(response.entry_points)

export const transformPrepareClosetPromotionOrderResponse = (
  response: Response<PromotedClosetOrderResp>,
) => transformClosetPromotionOrder(response.closet_promotion.closet_promotion_order)

export const transformSellerBadgesResponse = (response: Response<SellerBadgesResp>) =>
  response.badges.map(transformSellerBadgeDto)

export const transformClosetPromotionsResponse = (response: Response<PromotedClosetsListResp>) =>
  transformClosets(response.promoted_closets)

export const transformBumpMinimumPriceResponse = (response: Response<BumpMinimumPriceResp>) =>
  transformBumpMinimumPrice(response.minimum_price)

export const transformPrepareBumpOrderResponse = (response: Response<PrepareBumpOrderResp>) =>
  transformBumpUpOrder(response.push_up_order)

export const transformBumpOptionsResponse = (response: Response<BumpOptionResp>) =>
  transformBumpsOptions(response)

export const transformUserBundleItemsResponse = (response: Response<UserBundleItemsResp>) =>
  transformUserBundleItems(response)

export const transformGetSellerInsightsResponse = (response: Response<GetSellerInsightsResp>) => ({
  isVisible: response.is_visible,
  activeItemsStats: {
    ...response.active_items_stats,
    total: transformCurrencyAmountDto(response.active_items_stats.total),
  },
  soldItemsStats: {
    ...response.sold_items_stats,
    total: transformCurrencyAmountDto(response.sold_items_stats.total),
    period: {
      ...response.sold_items_stats.period,
      total: transformCurrencyAmountDto(response.sold_items_stats.period.total),
    },
  },
})

export const transformGetSellerInsightsPhotoRecommendationsResponse = (
  response: GetSellerInsightsPhotoRecommendationsResp,
) => ({
  status: response.status,
  itemsToImprove: response.items_to_improve.map(
    ({ item_id, photo_url, accessibility_description }) => ({
      itemId: item_id,
      photoUrl: photo_url,
      accessibilityDescription: accessibility_description,
    }),
  ),
})

export const transformInfluencerResponse = (
  response: InfluencerResp,
): InfluencerLandingPageModel => ({
  seo: transformInfluencerSeoDto(response.data[0].attributes.seo),
  introduction: transformInfluencerIntroductionDto(
    response.data[0].attributes.introduction_section,
  ),
  lifestyle: transformInfluencerLifestyleDto(response.data[0].attributes.lifestyle_section),
  closet: transformInfluencerClosetDto(response.data[0].attributes.closet_section),
  tips: transformInfluencerTipsDto(response.data[0].attributes.tips_section),
  inspiration: transformInfluencerInspirationDto(response.data[0].attributes.inspiration_section),
  disclaimer: transformInfluencerDisclaimerDto(response.data[0].attributes.disclaimer_section),
})

export const transformGetModelAttributeConfigurationResponse = (
  response: Response<GetModelAttributeConfigurationResp>,
) => ({ isVisible: response.visible, isRequired: response.required })

export const transformGetUploadFormModelsResponse = (
  response: Response<GetUploadFormModelsResp>,
) => ({
  sortingType: response.sorting_type,
  models: response.models,
})

export const transformGetSellerFiltersResponse = (response: Response<GetSellerFiltersResp>) =>
  response.filters.reduce((obj, filter) => ({ ...obj, [filter.name]: filter.count }), {})

export const transformLabelOptionsResponse = (response: Response<GetLabelOptionsResp>) =>
  transformLabelOptions(response.label_options)

export const transformRequestReturnDetailsResponse = (
  response: Response<GetRequestReturnDetailsResp>,
) => transformRequestReturnDetailsDto(response.request_return_details)

export const transformGetReturnShippingOptionsResp = (
  response: Response<GetReturnShippingOptionsResp>,
) => ({
  returnShippingOptions: transformReturnShippingOptionDtos(response.return_shipping_options),
  currencyConversion: response.currency_conversion
    ? transformReturnCurrencyConversionDto(response.currency_conversion)
    : null,
})

export const transformShippingContactConfigurationResp = (
  response: Response<ShippingContactConfigurationResp>,
) => transformShippingContactConfigurationDto(response.shipping_contact_configuration)

export const transformGetCustomShipmentConfig = (response: Response<CustomShipmentConfigResp>) =>
  transformCustomShipmentDto(response.custom_shipment_configuration)

export const transformDigitalLabelResponse = (response: Response<DigitalLabelResp>) =>
  transformDigitalLabel(response.digital_label)

export const transformShippingInstructionsResponse = (
  response: Response<GetShippingInstructionsResp>,
) => transformShippingInstructionsDto(response.shipping_instructions)

export const transformTrackingJourneySummaryResponse = (
  response: Response<TrackingJourneySummaryResponse>,
) => transformTrackingJourneySummary(response.journey_summary)

export const transformPickUpParcelCodeResponse = (response: Response<PickUpParcelCodeResponse>) =>
  transformPickUpParcelCode(response.parcel_collection_code)

export const transformShipmentInstructionsResponse = (
  response: Response<ShipmentInstructionsResponse>,
) => transformShipmentInstructions(response.shipment_instructions)

export const transformCollectionDatesResponse = (response: Response<GetCollectionDatesResp>) =>
  transformCollectionDates(response.collection_dates)

export const transformAvailableCarriersResponse = (response: Response<GetAvailableCarriersResp>) =>
  transformAvailableCarrierDtos(response.available_rates)

export const tranformCountryBoundsResponse = (response: Response<CountryBoundsResponse>) =>
  transformCountryBounds(response)

export const transformNearbyPickupPoints = (response: Response<NearbyPickupPointsResp>) => {
  return {
    nearbyShippingPoints: transformPickupPointsDto(response.nearby_shipping_points),
    nearbyShippingOptions: transformShippingOptionDtos(response.nearby_shipping_options),
    suggestedShippingPointCode: response.suggested_shipping_point_code,
  }
}

export const transformItemPerformanceResponse = (response: Response<ItemPerformanceResponse>) => {
  return response.item_performance ? transformItemPerformance(response.item_performance) : null
}

export const transformPublicPhotosResponse = (response: Response<GetPublicPhotosResp>) => {
  return {
    photos: response.photos.map(({ item_id, url }) => ({
      itemId: item_id,
      url,
    })),
  }
}

export const tranformPackageSizesResponse = (response: Response<PackageSizesResp>) => ({
  packageSizes: response.package_sizes.map(transformPackageSizeDto),
  parcelMeasurements: {
    enabled: response.parcel_measurements.enabled,
    suggestion: response.parcel_measurements.suggestion,
  },
})

export const transformBumpableItemsResponse = (response: Response<GetBumpableItemsResp>) => ({
  items: transformBumpItems(response.items),
  pageInfo: { hasMore: response.page_info.has_more },
})

export const transformElectroncisLandingPageResponse = (
  response: Response<ElectronicsLandingPageResp>,
): ElectronicsLandingPageModel => ({
  seo: transformElectroncisSeoDto(response.data[0].attributes.seo),
  introductionSection: transformElectroncisIntroductionSectionDto(
    response.data[0].attributes.introduction_section,
  ),
  whySection: transformWhySectionDto(response.data[0].attributes.why_section),
  howSection: transformHowSectionDto(response.data[0].attributes.how_section),
  discoverSection: transformDiscoverSectionDto(response.data[0].attributes.discover_section),
  bottomSection: transformBottomSectionDto(response.data[0].attributes.bottom_section),
  disclaimerSection: transformDisclaimerSectionDto(response.data[0].attributes.disclaimer_section),
})

export const transformNewAgainLandingPageResponse = (
  response: Response<NewAgainLandingPageResp>,
): NewAgainLandingPageModel => ({
  seo: transformNewAgainSeoDto(response.data[0].attributes.seo),
  introduction: transformNewAgainIntroductionSectionDto(
    response.data[0].attributes.introduction_section,
  ),
  potential: transformNewAgainPotentialSectionDto(response.data[0].attributes.potential_section),
  forNowever: transformNewAgainForNoweverlSectionDto(
    response.data[0].attributes.for_nowever_section,
  ),
  feelNewAgain: transformNewAgainFeelNewAgainDto(
    response.data[0].attributes.feel_new_again_section,
  ),
})

export const transformGetParcelDetailsResponse = (response: Response<GetParcelDetailsResp>) => ({
  packageSize: transformPackageSizeDto(response.package_size),
  maxMeasurementsExceeded: response.max_measurements_exceeded,
})

export const transformBumpsNewMemberItemUploadMotivationResponse = (
  response: Response<GetBumpsNewMemberItemUploadMotivationResp>,
) => ({
  title: response.title,
  subtitle: response.subtitle,
  expirationWarning: response.expiration_warning,
  ctaTitle: response.cta_title,
})

export const transformGetPackageSizesSuggestionResponse = (
  response: Response<GetPackageSizesSuggestionResp>,
) => ({
  packageSizeId: response.package_size_id,
})

export const transformEscrowOrderResponse = (response: Response<GetEscrowOrderShipmentResp>) =>
  transformEscrowOrderShipmentDto(response)

export const transformEscrowOrderComplaintInfoResponse = (
  response: Response<GetEscrowOrderComplaintInfoResp>,
) =>
  response.complaint
    ? {
        complaintId: response.complaint.complaint_id,
        isNewComplaint: response.complaint.is_new_complaint,
      }
    : null

export const transformShippingPreferenceResponse = (
  response: Response<GetShippingPreferencesResp>,
): ShippingPreferencesModel => transformShippingPreferencesDtoToModel(response)

export const transformEscrowOrderActionsResponse = (
  response: Response<GetEscrowOrderActionsResp>,
) => transformEscrowOrderActonsDto(response)
