export enum ItemBlockCtaType {
  Header = 'header',
  Item = 'item',
}

export enum BlockEntityType {
  ItemBoxBlock = 'item_box_block',
  ThumbnailsBlock = 'thumbnails_block',
  ExposureBlock = 'exposure_block',
  BannersBlock = 'banners_block',
  Header = 'header',
  AdOrCloset = 'ad_or_closet',
  Item = 'item',
  ListerActivationBanner = 'lister_activation_banner',
  BrazePromobox = 'braze_promobox',
}

export enum ThumbnailsBlockStyle {
  SmallImage = 'small_image',
  BigImage = 'big_image',
  TwoHorizontalRows = 'two_horizontal_rows',
  SmallRoundImage = 'small_round_image',
}

export const ALLOWED_TEXT_THEMES = ['light', 'dark'] as const
export const ALLOWED_BUTTON_THEMES = ['primary', 'amplified'] as const
export const SINGLE_SLOT_BLOCKS = [BlockEntityType.Item, BlockEntityType.BrazePromobox]
